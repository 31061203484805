import React from 'react'
import MetaTags from '../components/MetaTags';

function Startupstudio() {
  return (
    <>
      <MetaTags
        title='Bienvenue dans notre Startup Studio'
        description='Transformons votre idée en Startup. Cofondons ensemble et bénéficiez de notre expertise d’entrepreneur innovant pour changer le monde.'
        image='assets/img/inner-pages/startup-studio.jpg'
        name='Jelam'
      />
      <div className='stg-container'>
        {/* Section: Intro */}
        <section>
          <div className="align-center stg-bottom-gap">
            <h1 className="bringer-page-title" data-appear="fade-up" data-unload="fade-up">Notre Startup Studio</h1>
          </div>
          {/* Section Image */}
          <div className="bringer-parallax-media stg-bottom-gap-l" data-appear="fade-up" data-unload="fade-up">
            <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/startup-studio.jpg" alt="Startup Studio" width="1920" height="960" />
          </div>

          {/* Section Title */}
          <div className="stg-row stg-bottom-gap">
            <div className="stg-col-6" data-unload="fade-left">
              <h2 className="stg-bottom-gap" data-split-appear="fade-up">Allumons l'étincelle ✨ .</h2>
            </div>
            <div className="stg-col-6"></div>
          </div>

          {/* Section Content */}
          <div className="stg-row stg-tp-column-reverse">
            <div className="stg-col-6 stg-valign-bottom" data-unload="fade-left">
              <div className="align-right">
                <a href="/contact" className="bringer-icon-link" data-appear="fade-right">
                  <div className="bringer-icon-link-content">
                    <h6>
                      Libérons
                      <br />
                      votre potentiel
                    </h6>
                    <span className="bringer-label">Rejoignez une de nos équipes</span>
                  </div>
                  <div className="bringer-icon-wrap">
                    <i className="bringer-icon bringer-icon-explore"></i>
                  </div>
                </a>{/* .bringer-icon-link */}
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-9 stg-tp-bottom-gap" data-appear="fade-left" data-unload="fade-right">
              <p className="bringer-large-text">Nous construisons un contexte stimulant pour faire naître les grandes idées.</p>
            </div>
          </div>
        </section>


        {/* Section: Portfolio List */}
        <section>
          {/* Section Title */}
          <div className="stg-row bringer-section-title">
            <div className="stg-col-8 stg-offset-2">
              <div className="align-center">
                <h2 data-appear="fade-up" data-unload="fade-up">Qu'est-ce qui nous rend unique ?</h2>
                <p className="bringer-large-text" data-appear="fade-up" data-unload="fade-up" data-delay="100">Nous vous accueillons dès la genèse de l'idée. Ensemble, nous construisons un contexte stimulant par une approche proactive et immersive. Nous ne nous contentons pas de vous accompagner, nous co-créons avec vous et partageons le risque.</p>
              </div>
            </div>
          </div>
          {/* Portfolio List */}
          <div className="stg-row stg-valign-middle stg-tp-column-reverse">
            <div className="stg-col-6" data-unload="fade-left" data-delay="100">
              <div className="bringer-grid-1col">


                {/* Portfolio List Item */}
                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="300">
                  <div className="bringer-portfolio-thmb">
                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/ecoute.jpg" alt="écoute" width="1200" height="1200" />
                  </div>
                  <div className="bringer-portfolio-short-title">
                    <span className="bringer-meta">Écoute</span>
                    <p>Une <span className="bringer-accent">approche hollistique</span> tenant compte de votre unicité<span className="bringer-accent">.</span></p>
                  </div>
                </div>{/* .bringer-portfolio-short-item */}

                {/* Portfolio List Item */}
                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="100">
                  <div className="bringer-portfolio-thmb">
                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/engagement.jpg" alt="Engagés à vos côtés" width="1200" height="1200" />
                  </div>
                  <div className="bringer-portfolio-short-title">
                    <span className="bringer-meta">Engagement</span>
                    <p>Un <span className="bringer-accent">engagement constant</span> à vos côtés<span className="bringer-accent">.</span></p>
                  </div>
                </div>{/* .bringer-portfolio-short-item */}

                {/* Portfolio List Item */}
                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="200">
                  <div className="bringer-portfolio-thmb">
                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/experience.jpg" alt="Expérience entrepreneuriale" width="1200" height="1200" />
                  </div>
                  <div className="bringer-portfolio-short-title">
                    <span className="bringer-meta">Expérience</span>
                    <p>Une <span className="bringer-accent">méthodologie structurée</span> et éprouvée<span className="bringer-accent">.</span></p>
                  </div>
                </div>{/* .bringer-portfolio-short-item */}

                {/* Portfolio List Item */}
                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="0">
                  <div className="bringer-portfolio-thmb">
                    <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/entrepreneuriat.jpg" alt="Entrepreneuriat" width="1200" height="1200" />
                  </div>
                  <div className="bringer-portfolio-short-title">
                    <span className="bringer-meta">Entrepreneuriat</span>
                    <p>Un <span className="bringer-accent">accompagnement</span> vers l’entrepreneuriat<span className="bringer-accent"> sur mesure</span><span className="bringer-accent">.</span></p>
                  </div>
                </div>{/* .bringer-portfolio-short-item */}

                {/* Learn More */}
                <div className="bringer-portfolio-short-item bringer-portfolio-short-more" data-appear="fade-right" data-delay="400">
                  <div className="bringer-portfolio-short-title">
                    <span className="bringer-label">Prêt pour l'aventure</span>
                    <h6>Lancez-vous</h6>
                  </div>
                  <span className="bringer-icon bringer-icon-explore"></span>
                  {/* eslint-disable-next-line */}
                  <a href="/contact"></a>
                </div>{/* .bringer-portfolio-short-more */}
              </div>
            </div>
            <div className="stg-col-6 stg-tp-bottom-gap" data-unload="fade-right" data-delay="200">
              <div className="bringer-parallax-media" data-appear="fade-left">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/dream.jpg" alt="Pret pour l'aventure" width="960" height="1095" />
              </div>
            </div>
          </div>
        </section>

        {/* Section: Offer */}
        <section>
          {/* Section Title */}
          <div className="stg-row stg-bottom-gap-l">
            <div className="stg-col-7">
              <h2 data-split-appear="fade-up" data-unload="fade-up">Où en êtes-vous ?</h2>
            </div>
            <div className="stg-col-5">Que vous en soyez à l’étape de l’idée, que vous ayez déjà testé des choses, ou que vous soyez simplement animé par une envie irrésistible d’entreprendre, nous sommes là pour vous accompagner.</div>
          </div>
          {/* Services Grid */}
          <div className="bringer-grid-3cols bringer-tp-grid-2cols bringer-tp-stretch-last-item" data-stagger-appear="fade-up" data-stagger-unload="fade-up">
            {/* Item 01 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Cofondons une Startup Ensemble<span className="bringer-accent">.</span></h5>
              <p>Vous avez une idée inspirante et vous cherchez des partenaires de confiance pour la développer.</p>
            </div>
            {/* Item 02 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Accélérons un Projet Existant<span className="bringer-accent">.</span></h5>
              <p>Votre projet est déjà en cours, mais vous sentez qu’il a besoin d’un coup de pouce pour atteindre son plein potentiel.</p>
            </div>
            {/* Item 03 */}
            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
              <h5>Lancons un Nouveau Projet Ensemble<span className="bringer-accent">.</span></h5>
              <p>Vous avez l'envie d'entreprendre, mais vous n'avez pas encore d'idée précise.</p>
            </div>
          </div>
        </section>


        {/* Section: Details */}
        <section className="backlight-both">
          {/* Section Title */}
          <div className="stg-row stg-bottom-gap-l">
            <div className="stg-col-7">
              <h2 data-split-appear="fade-up" data-unload="fade-up">Voici comment nous donnons naissance à votre idée:</h2>
            </div>
            <div className="stg-col-5"></div>
          </div>
          {/* Details 01 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
            <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/commission.jpg" alt="Commission" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
              <span className="bringer-label">Étape 01</span>
              <h3>Sélection et Alchimie</h3>
              <p>La première étape pour rejoindre Jelam consiste à présenter votre projet ou votre idée devant notre jury de sélection. Cette commission est l'occasion de révéler votre vision, d'explorer ensemble les synergies possibles, et de s'assurer que nos valeurs et nos ambitions sont en parfaite harmonie.</p>
            </div>
          </div>
          {/* Details 02 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
            <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/winwin.jpg" alt="Negociation" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
              <span className="bringer-label">Étape 02</span>
              <h3>Protection des Intérêts</h3>
              <p>Une fois le projet validé, nous passons à la négociation des conditions de cofondation. Chez Jelam, nous veillons à ce que chaque partie soit protégée et que les intérêts de chacun soient préservés.</p>
            </div>
            <div className="stg-col-3">{/* Empty Column */}</div>
          </div>
          {/* Details 03 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
            <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/developpement.jpg" alt="Devops" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
              <span className="bringer-label">Étape 03</span>
              <h3>Développement Produit</h3>
              <p>Une fois nos accords en place, nous entrons dans le vif du sujet : le développement de votre produit. Ensemble, nous transformons votre concept en une solution concrète et performante, en utilisant les méthodes Agiles et l’UX/UI Design.</p>
            </div>
          </div>
          {/* Details 04 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
            <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/launch.jpg" alt="Go To Market" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
              <span className="bringer-label">Étape 04</span>
              <h3>Mise sur le marché</h3>
              <p>Une fois le produit prêt, nous élaborons ensemble une stratégie Go To Market efficace, pour que votre lancement soit un succès immédiat.</p>
            </div>
            <div className="stg-col-3">{/* Empty Column */}</div>
          </div>
          {/* Details 05 Row */}
          <div className="stg-row stg-valign-middle" data-unload="fade-left">
            <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/support.jpg" alt="Support" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
              <span className="bringer-label">Étape 05</span>
              <h3>Suivi et Croissance</h3>
              <p>Après le lancement, notre travail ne s'arrête pas. Nous continuons de vous accompagner pour ajuster la stratégie, analyser les performances, et saisir de nouvelles opportunités de croissance.</p>
            </div>
          </div>
        </section>

        {/* Section: Our Story */}
        <section className="backlight-top">
          <div className="stg-row stg-large-gap stg-m-normal-gap">
            <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-appear="fade-right" data-unload="fade-left">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/QSTOM.jpg" alt="Startup QSTOM" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-vertical-space-between" data-appear="fade-left" data-unload="fade-right">
              <div>
                <h2>Découvrez l'histoire de QSTOM</h2>
                <p className="bringer-large-text">En moins de 6 mois nous avons co-créé la Startup QSTOM.</p>
                <p>QSTOM permet à chaque individu, chaque entreprise de partager des contenus mémorables, ouvrant ainsi la porte à une nouvelle dimension de communication.</p>
              </div>
              <a href="/qstom" className="bringer-icon-link">
                <div className="bringer-icon-wrap">
                  <i className="bringer-icon bringer-icon-explore"></i>
                </div>
                <div className="bringer-icon-link-content">
                  <h6>
                    Envie d'en
                    <br />
                    Savoir plus
                  </h6>
                  <span className="bringer-label">Le Projet QSTOM</span>
                </div>
              </a>{/* .bringer-icon-link */}
            </div>
          </div>
        </section>

        {/* Section: FAQ */}
        <section>
          {/* Section Title */}
          <div className="stg-row stg-bottom-gap-l">
            <div className="stg-col-8 stg-offset-2">
              <div className="align-center">
                <h2 data-split-appear="fade-up" data-unload="fade-up">Vous avez encore des questions ?</h2>
                <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Voici quelques questions qui reviennent fréquemment</p>
              </div>
            </div>
          </div>
          {/* FAQ List */}
          <div className="bringer-faq-list">
            {/* FAQ Item 01 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">General</span>
                <h4>
                  <sup>01.</sup>
                  Pourquoi choisir notre Startup Studio ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>Avec JELAM, vous n'êtes jamais seul. Vous bénéficiez de l'accompagnement de nos experts, de nos ressources et de notre réseau pour transformer vos idées en succès commerciaux. Nous croyons que tout le monde peut développer de bonnes idées avec le bon accompagnement. En rejoignant notre Startup Studio, vous gagnerez en crédibilité, réduirez les risques et verrez votre rêve se réaliser.</p>
              </div>
            </div>
            {/* FAQ Item 02 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">General</span>
                <h4>
                  <sup>02.</sup>
                  Comment se déroule une commission ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>Les commissions sont l’occasion de présenter votre idée à un jury d’entrepreneurs et d’experts. Durant un entretien de 30 minutes, vous pourrez nous expliquer le « Pourquoi » de votre projet, vos ambitions et surtout vos attentes. À l’issu de la commission, nous étudierons la recevabilité de votre projet et le cas échéant, les modalités d’accompagnement.</p>
              </div>
            </div>
            {/* FAQ Item 02 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">General</span>
                <h4>
                  <sup>03.</sup>
                  Qu'attendez-vous des cofondateurs ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>Nous attendons des cofondateurs un engagement total, de la disponibilité, une transparence sincère et une volonté de partager les risques. Chez Jelam, nous croyons profondément en la force de la co-création. Nous sommes là pour vous accompagner à chaque étape du chemin, mais nous savons que le leadership de votre startup doit venir de vous. C’est pourquoi nous préférons que vous assumiez la présidence ou que vous identifiiez la personne idéale pour le faire. Jelam n’a pas vocation à diriger les startups, notre mission est de vous soutenir, de vous apporter notre expertise et de co-créer avec vous pour transformer vos idées en succès durables.</p>
              </div>
            </div>
            {/* FAQ Item 03 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">Service</span>
                <h4>
                  <sup>04.</sup>
                  Quelle est la différence avec un incubateur ou un accélérateur ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>Un incubateur fournit des ressources et un espace de travail aux Startups naissantes, tandis qu'un accélérateur aide les startups existantes à croître plus rapidement en leur fournissant du mentorat et des investissements. Chez Jelam, en tant que Startup Studio, nous co-fondons les entreprises, partageons les risques, et nous investissons activement dans leur réussite.</p>
              </div>
            </div>
            {/* FAQ Item 04 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">Service</span>
                <h4>
                  <sup>05.</sup>
                  Quels sont les critères de sélection ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>Nous analysons soigneusement le potentiel de votre idée. Pour cela, nous nous intéressons non seulement aux potentiels marché et financier mais également à l’impact sociétal du projet. Nous nous assurons également que nous avons les ressources complémentaires pour transformer votre idée en innovation rentable.</p>
              </div>
            </div>
            <hr />
            {/* FAQ Item 05 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">Financement</span>
                <h4>
                  <sup>06.</sup>
                  Prenez-vous une participation au capital ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>À l'instar de l'IT For Equity, Jelam prend une participation au capital à hauteur de 15%. Cette participation reflète notre engagement en tant que cofondateurs à vos côtés, partageant avec vous les risques et les succès du projet. En investissant dans votre entreprise, nous devenons de véritables partenaires, dédiés à la réussite de votre vision.</p>
              </div>
            </div>
            {/* FAQ Item 06 */}
            <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
              <div className="bringer-toggles-item--title">
                <span className="bringer-label">Financement</span>
                <h4>
                  <sup>07.</sup>
                  Faites-vous un apport en numéraire ?
                </h4>
              </div>
              <div className="bringer-toggles-item--content">
                <p>Oui, chez Jelam, nous faisons un apport en numéraire sous forme d’apport en compte courant d’associé. Le montant de cet apport est variable en fonction des besoins spécifiques de chaque projet. Cependant, il est important que les fonds propres de départ soient diversifiés. Outre notre apport, le capital social et les apports en compte courant des autres associés, y compris le vôtre, sont essentiels. Nous encourageons les fondateurs à mobiliser des fonds provenant de différentes sources, telles que la love money, les prêts d’honneur et les subventions, pour constituer une base financière solide.</p>
              </div>
            </div>
          </div>
        </section>


        {/* Section: Grid CTA */}
        <section>
          <div className="bringer-bento-grid bringer-grid-cta">
            <div className="is-large bringer-masked-block" data-appear="fade-right" data-unload="fade-left">
              <div className="bringer-grid-cta-media bringer-masked-media" data-bg-src="assets/img/cta/aventure.jpg">
                <div className="bringer-grid-cta-heading">Prêt pour l'aventure ?</div>
              </div>
              <div className="bringer-masked-content at-bottom-right">
                <p className="bringer-large-text" data-appear="fade-right" data-delay="100">Collaborons ensemble &rarr;</p>
              </div>
            </div>
            <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
              <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                <div>
                  <h6>Vous voulez devenir</h6>
                  <span className="jelam-special-word">fondateur</span><span className="bringer-accent">.</span>
                </div>
                <span>Nous recrutons régulièrement des pilotes pour nos Startups.</span>
              </div>
            </div>
            <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
              <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                <div>
                  <h6>Vous cherchez à</h6>
                  <span className="jelam-special-word">Investir</span><span className="bringer-accent">.</span>
                </div>
                <span>Nous sommes toujours en veille pour trouver de nouveaux partenaires.</span>
              </div>
            </div>
            <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
              <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                <div>
                  <h6>Vous avez une idée de</h6>
                  <span className="jelam-special-word">Startup</span><span className="bringer-accent">.</span>
                </div>
                <span>Nous cherchons en permanence des partenaires créatifs.</span>
              </div>
            </div>
            <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
              <a href="/contact" className="bringer-square-button">
                <span className="bringer-icon bringer-icon-explore"></span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Startupstudio