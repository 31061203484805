import { React, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/MetaTags';

function Founder() {

    const [fullname, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [response, setResponse] = useState("");
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let origin = location.pathname.length > 1 ? location.pathname.replace('/', '').charAt(0).toUpperCase() + location.pathname.slice(2) : 'Home';

            let res = await fetch("https://hook.eu2.make.com/iauzb7zypebiqon5rdbslwtnmglbbgkf", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fullname: fullname,
                    phoneNumber: phoneNumber,
                    origine: origin
                }),
            });
            const resJson = await res;
            if (res.status === 200) {
                setFullName("");
                setPhoneNumber("");
                setResponse("Nous vous contactons rapidement.");
            } else {
                setResponse("Oups ! Une erreur s'est produite.");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <MetaTags
                title='Vous souhaitez devenir cofondateur'
                description="De l'intuition au marché, partageons une aventure humaine. Développons, donnons vie et valorisons votre idée ensemble grâce à un accompagnement sur mesure."
                image='assets/img/inner-pages/aventure.jpg'
                name='Jelam'
            />
            <div className='stg-container'>
                {/* Hero Section */}
                <section>
                    <div className="bringer-hero-block bringer-hero-type06">
                        <div className="bringer-bento-grid stg-bottom-gap">
                            <div className="is-large bringer-masked-block" data-appear="fade-right" data-unload="fade-left">
                                <div className="bringer-bento-hero-media bringer-masked-media" data-bg-src="assets/img/inner-pages/concretiserlidee.jpg">
                                    {/* Title */}
                                    <h1 className="bringer-page-title">Donnons vie à vos idées</h1>
                                    {/* Description */}
                                </div>
                                <div className="bringer-masked-content at-bottom-right">
                                    <a href="#page06" className="bringer-square-button" data-appear="zoom-in" data-delay="100">
                                        <span className="bringer-icon bringer-icon-arrow-down"></span>
                                    </a>
                                </div>
                            </div>
                            <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/greentech.jpg" alt="Greentech" width="1200" height="1200" />
                            </div>
                            <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/medtech.jpg" alt="Medtech" width="1200" height="1200" />
                            </div>
                            <div className="is-medium bringer-block stg-vertical-space-between" data-appear="fade-left" data-delay="300" data-unload="fade-right">
                                <h3>Bienvenue chez JELAM, où votre créativité rencontre notre expertise.</h3>
                                <p>Nous croyons que chaque idée, même les plus audacieuses, méritent d'être écoutée, explorées et challengées. Nous vous offrons un environnement où votre créativité peut s'épanouir, en alliant notre expertise pour transformer vos visions en produits concrets.</p>
                            </div>
                        </div>
                    </div>{/* .bringer-hero-block */}
                </section>

                {/* Section: Steps */}
                <section className="backlight-top">
                    <div className="stg-bottom-gap-l">
                        <h2 data-appear="fade-up" data-unload="fade-up">Où en êtes-vous Aujourd'hui ?</h2>
                    </div>
                    {/* Step 01 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/way.jpg" alt="Par quoi commencer" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <h2>Vous vous demandez par où commencer ?</h2>
                            <p>Nous comprenons que le premier pas est difficile. Ensemble, nous structurons vos idées pour les transformer en actions concrètes, vous permettant d'avancer avec confiance.</p>
                        </div>
                    </div>
                    {/* Step 02 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/ensemble.jpg" alt="Ensemble" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <h2>Vous craignez d'être seul ?</h2>
                            <p>Avec Jelam, vous n'êtes jamais seul. Nous formons une équipe dédiée qui partage vos ambitions et vous soutient à chaque étape.</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                    {/* Step 03 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/recycle.jpg" alt="Recycler" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <h2>Vous rêvez de changer le monde ?</h2>
                            <p>Nous partageons votre vision. Ensemble, nous créons des solutions innovantes qui répondent aux besoins réels et laissent une empreinte positive.</p>
                        </div>
                    </div>
                    {/* Step 04 Row */}
                    <div className="stg-row stg-valign-middle stg-tp-row-reverse" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/aventure.jpg" alt="Aventurier" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <h2>Vous êtes prêt pour l'aventure ?</h2>
                            <p>Créer une startup est une aventure unique. Avec Jelam, cette expérience devient collective, où chaque défi est une opportunité de grandir et chaque succès est partagé.</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                </section>

                {/* Section: Services */}
                <section className="backlight-top" id="page06">
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Nous co-créons à vos côtés</h2>
                            </div>
                        </div>
                    </div>
                    {/* List with Preview */}
                    <div className="bringer-list-with-preview" data-preview-position="left" data-unload="fade-up" data-delay="200">
                        <div className="bringer-lwp-roster">
                            {/* Item 01 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/aventure2.jpg" alt="Aventure" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Vivons une aventure enrichissante<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous faisons de chaque défi une opportunité.
                                        </li>
                                        <li>
                                            Nous vous guidons à chaque étape de votre parcours entrepreneurial.
                                        </li>
                                        <li>
                                            Nous partageons des histoires inspirantes pour vous faire grandir.
                                        </li>
                                        <li>
                                            Nous célébrons toutes les petites victoires.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Item 02 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/strategy.jpg" alt="Stratégie" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Concrétisons votre idée et planifions son développement<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous structurons vos idées avec créativité et pragmatisme.
                                        </li>
                                        <li>
                                            Nous validons chaque étape par des prototypes.
                                        </li>
                                        <li>
                                            Nous restons agiles pour ajuster rapidement.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Item 03 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/successroad.jpg" alt="Succès" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Mettons toutes les chances de notre côté<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous mobilisons notre réseau d'experts.
                                        </li>
                                        <li>
                                            Nous adressons tous les aspects du lancement de produit.
                                        </li>
                                        <li>
                                            Nous affinons nos stratégies avec des données précises.
                                        </li>
                                        <li>
                                            Nous anticipons les obstacles.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Item 04 */}
                            <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                                <img src="assets/img/inner-pages/positiveimpact.jpg" alt="Impact positif" width="800" height="1200" />
                                <div className="bringer-lwp-item-content">
                                    <h5>Maximisons l'impact positive<span className="bringer-accent">.</span></h5>
                                    <ul>
                                        <li>
                                            Nous intégrons l'écoconception dès le démarrage.
                                        </li>
                                        <li>
                                            Nous cherchons des partenariats pertinent.
                                        </li>
                                        <li>
                                            Nous nous adressons aux clients et aux utilisateurs.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Learn More */}
                            <div className="align-right">
                                <a href="/startupstudio" className="bringer-arrow-link">Devenir un cofondateur JELAM</a>
                            </div>
                        </div>
                    </div>{/* .bringer-list-with-preview */}
                </section>

                {/* Section: CTA Form */}
                <section data-padding="bottom">
                    <div className="bringer-masked-cta bringer-masked-block" data-unload="fade-down">
                        <form onSubmit={handleSubmit} method="post" data-fill-error="Veuillez compléter les champs obligatoires." className="bringer-contact-form is-short bringer-masked-media" data-appear="fade-up">
                            <div className="bringer-form-content bringer-cta-form">
                                <div className="bringer-cta-form-content" data-appear="fade-up" data-delay="100">
                                    <div className="bringer-cta-title">Prêt à participer à une commission ?</div>
                                    <input type="text" id="fullname" name="fullname" placeholder="Votre nom" required value={fullname} onChange={(e) => setFullName(e.target.value)} />
                                    <input type="tel" pattern="^(\+33|0)[1-9](\d{2}){4}$" id="phoneNumber" name="phoneNumber" placeholder="Votre numéro" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                                <div className="bringer-cta-form-button" data-appear="fade-up" data-delay="200">
                                    <button type="submit">
                                        <span className="bringer-icon bringer-icon-arrow-submit"></span>
                                    </button>
                                </div>
                                <div className="bringer-contact-form__response">{response}</div>
                            </div>
                            <span className="bringer-form-spinner"></span>
                        </form>
                        <div className="bringer-masked-cta-content bringer-masked-content at-top-right">
                            <p className="bringer-large-text" data-appear="fade-down">Rejoignez notre équipage. ✨</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Founder