import React from 'react'

function qstom() {
    return (
        <div className='stg-container'>
            <section>
                {/* Block: Page Title */}
                <div className="bringer-bento-grid stg-bottom-gap-l">
                    <div className="is-large" data-appear="fade-right" data-unload="fade-left">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/GIF_QSTOM.gif" alt="ALTTODO" width="1200" height="1200" />
                    </div>
                    <div className="is-medium bringer-block stg-vertical-space-between" data-appear="fade-down" data-delat="100" data-unload="fade-right">
                        <h1>QSTOM</h1>
                        <p>De l'idée à la croissance, découvrez comment nous avons co-créé la Startup QSTOM en 6 mois.</p>
                    </div>
                    <div className="is-small bringer-block" data-appear="fade-up" data-delat="100" data-unload="fade-left">
                        <ul className="bringer-meta-list">
                            <li>
                                <span className="bringer-meta">Cofondateur:</span>
                                <span className="bringer-highlight">Mélanie</span>
                            </li>
                            <li>
                                <span className="bringer-meta">Studio:</span>
                                <span className="bringer-highlight">Startup Studio</span>
                            </li>
                        </ul>
                    </div>
                    <div className="is-small" data-appear="fade-left" data-delat="200" data-unload="fade-right">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/qstom_founder.jpg" alt="qstom founder" width="960" height="960" />
                    </div>
                </div>

                {/* Block: The Challenge */}
                <div className="stg-row stg-bottom-gap-l stg-tp-column-reverse">
                    <div className="stg-col-8 bringer-block stg-vertical-space-between" data-appear="fade-right" data-unload="fade-left">
                        <h2>Le Challenge</h2>
                        <div>
                            <p>Révolutionner la communication avec des QR Codes dynamiques, personnalisables avec une photo ou une vidéo.</p>
                            <ul className="bringer-marked-list">
                                <li>Démontrer l'intérêt du concept auprès du public.</li>
                                <li>Identifier des cas d'usage pertinents.</li>
                                <li>Créer une expérience utilisateur simple et attrayante.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="stg-col-4 stg-tp-bottom-gap" data-appear="fade-left" data-unload="fade-right">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/challenger.jpg" alt="challenge qstom" width="960" height="960" />
                    </div>
                </div>

                {/* Block: Our Approach */}
                <div className="stg-row stg-bottom-gap-l">
                    <div className="stg-col-4 stg-tp-bottom-gap" data-appear="fade-right" data-unload="fade-left">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/brainstorming qstom.jpg" alt="ALTTODO" width="960" height="960" />
                    </div>
                    <div className="stg-col-8 bringer-block stg-vertical-space-between" data-appear="fade-left" data-unload="fade-right">
                        <h2>Notre approche</h2>
                        <h6>Structure et méthode</h6>
                        <div>
                            <ul className="bringer-marked-list">
                                <li><span className="bringer-accent">Brainstorming</span> pour générer des idées novatrices.</li>
                                <li><span className="bringer-accent">Design Thinking</span> pour centrer le développement sur l'utilisateur.</li>
                                <li><span className="bringer-accent">Scrum</span> pour une gestion agile du projet.</li>
                                <li><span className="bringer-accent">Go To Market</span> pour une commercialisation efficace.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Block: The Solution */}
                <div className="bringer-bento-grid stg-bottom-gap-l">
                    <div className="is-large stg-vertical-space-between bringer-block stg-large-gap" data-bg-src="assets/img/cta/qstom_mockup_site.jpg" data-appear="fade-right" data-unload="fade-left">
                        <h2>Ce que nous avons réalisé</h2>
                    </div>

                    <div className="is-medium bringer-block stg-valign-middle" data-appear="fade-left" data-delay="100" data-unload="fade-right">
                        <ul className="bringer-marked-list">
                            <li>Créé l'identité de marque.</li>
                            <li>Conçu le produit physique.</li>
                            <li>Prototypé et Développé le produit numérique sous forme de solution SaaS (logiciel en tant que service).</li>
                            <li>Élaboré la stratégie de mise sur le marché.</li>
                        </ul>
                    </div>
                    <div className="is-small" data-appear="fade-up" data-delay="100" data-unload="fade-left">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/carte_qstom.jpg" alt="ALTTODO" width="960" height="960" />
                    </div>
                    <div className="is-small" data-appear="fade-up" data-delay="200" data-unload="fade-right">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/qstom_mockup_mères.jpg" alt="cartes fêtes des mères QSTOM" width="960" height="960" />
                    </div>
                </div>

                {/* Block: Results & Impact */}
                <div className="stg-row">
                    <div className="stg-col-8 bringer-block stg-vertical-space-between stg-tp-bottom-gap" data-appear="fade-right" data-unload="fade-left">
                        <h2>Résultats et succès</h2>
                        <ul className="bringer-marked-list">
                            <li><span className="bringer-highlight">En moins de 6 mois</span> QSTOM a été créée et leur produit a été lancé.</li>
                            <li><span className="bringer-highlight">100% des utilisateurs</span> ont compris le produit dès la première utilisation.</li>
                            <li><span className="bringer-highlight">Les premières ventes</span> ont été réalisées immédiatement après le lancement.</li>
                        </ul>
                    </div>
                    <div className="stg-col-4" data-appear="fade-left" data-unload="fade-right">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/succes.jpg" alt="succès" width="960" height="960" />
                    </div>
                </div>
            </section>

            {/* Section: The Challenge */}
            <section>
                <div className="stg-row">
                    <div className="stg-col-6 stg-tp-bottom-gap-l" data-unload="fade-left">
                        <div className="bringer-sticky-block">
                            <h2 data-appear="fade-right">Ça s'est réellement produit </h2>
                        </div>
                    </div>
                    <div className="stg-col-6 stg-tp-col-10 stg-tp-offset-2 stg-m-col-11 stg-m-offset-1" data-unload="fade-right">
                        <div className="stg-bottom-gap-section stg-tp-bottom-gap-l" data-appear="fade-up">
                            <h3>Innover c'est générer de l'émotion</h3>
                            <p>Nous avons fait et testé de nombreux prototypes mais aucun n’a eu un tel effet. Avec des moyens restreints et beaucoup d’engagement, chacun de nous a offert une carte de voeux fête des mères, avec un QSTOM, à nos mamans générant un moment inoubliable qui restera dans nos mémoires.</p>
                            <p>Le résultat était sans appel, nous souhaitions offrir ce sentiment au monde.</p>
                        </div>
                        <div data-appear="fade-up">
                            <h3>Nos valeurs comme guide</h3>
                            <p>Lors de nos sessions de brainstorming, nous avons réalisé l'importance d'assurer la sécurité de nos utilisateurs contre les contenus inadaptés ou malveillants.</p>
                            <p>Cette prise de conscience nous a amenés à intégrer la gestion de contenu sécurisé dans les valeurs de l'entreprise et à ajuster la feuille de route du produit pour y inclure cette fonctionnalité essentielle.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Testimonials */}
            <section>
                {/* Section Title */}
                <div className="stg-row bringer-section-title">
                    <div className="stg-col-8 stg-offset-2">
                        <div className="align-center">
                            <h2 data-appear="fade-up" data-unload="fade-up">Témoignages inspirants</h2>
                        </div>
                    </div>
                </div>
                {/* Grid Testimonials */}
                <div className="bringer-grid-2cols" data-stagger-appear="fade-up" data-stagger-unload="fade-up">
                    {/* Card Item 01 */}
                    {/* <div className="bringer-block bringer-tetimonials-card">
                        <div className="bringer-tetimonials-card-descr">
                            "Aptent ad urna ipsum est etiam! Lacinia vivamus risus pulvinar luctus; metus est curae pretium. Commodo ut dictumst montes velit aenean nullam. Ex turpis pellentesque posuere molestie ridiculus donec. Tincidunt varius augue lacinia egestas sagittis diam nec leo. Nunc eget per scelerisque purus platea lacinia dis integer. Dui facilisi urna per; morbi magna finibus. Iaculis bibendum gravida vulputate egestas diam tincidunt."
                        </div>
                        <div className="bringer-tetimonials-card-footer">
                            <div className="bringer-tetimonials-card-name">
                                <h6>Mélanie</h6>
                                <span className="bringer-meta">Cofondatrice de QSTOM</span>
                            </div>
                            <div className="bringer-tetimonials-card-rate">
                                <span className="bringer-tetimonials-stars5"></span>
                            </div>
                        </div>
                    </div> */}
                    {/* .bringer-tetimonials-card */}
                    {/* Card Item 02 */}
                    <div className="bringer-block bringer-tetimonials-card">
                        <div className="bringer-tetimonials-card-descr">
                            "QSTOM est notre première fille ! En tant que fondateur de JELAM, cette Startup a une saveur particulière puisqu’elle vient prouver notre capacité à réaliser notre projet. Nous avons énormément appris et nous sommes très fiers du travail accompli."
                        </div>
                        <div className="bringer-tetimonials-card-footer">
                            <div className="bringer-tetimonials-card-name">
                                <h6>Jean-Noël</h6>
                                <span className="bringer-meta">CEO de JELAM</span>
                            </div>
                            <div className="bringer-tetimonials-card-rate">
                                <span className="bringer-tetimonials-stars5"></span>
                            </div>
                        </div>
                    </div>{/* .bringer-tetimonials-card */}
                </div>
            </section>
        </div>
    )
}

export default qstom