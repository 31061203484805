import { React, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MetaTags from '../components/MetaTags';

function Company() {

  const [fullname, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [response, setResponse] = useState("");
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let origin = location.pathname.length > 1 ? location.pathname.replace('/', '').charAt(0).toUpperCase() + location.pathname.slice(2) : 'Home';

      console.log(JSON.stringify({
        fullname: fullname,
        phoneNumber: phoneNumber,
        origine: origin
      }));
      let res = await fetch("https://hook.eu2.make.com/iauzb7zypebiqon5rdbslwtnmglbbgkf", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fullname: fullname,
          phoneNumber: phoneNumber,
          origine: origin
        }),
      });
      const resJson = await res;
      if (res.status === 200) {
        setFullName("");
        setPhoneNumber("");
        setResponse("Nous vous contactons rapidement.");
      } else {
        setResponse("Oups ! Une erreur s'est produite.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <MetaTags
        title='Vous êtes une PME ou une ETI'
        description="PME, ETI, découvrez comment des technologies comme le Cloud, l'IA et l'IoT peuvent accélérer votre croissance et vous aider à gagner des parts de marché."
        image='assets/img/inner-pages/pme_innovation.jpg'
        name='Jelam'
      />
      <div className='stg-container'>
        {/* Hero Section */}
        <section>
          <div className="bringer-hero-block bringer-hero-type01">
            {/* Main Line */}
            <div className="stg-row stg-large-gap stg-valign-middle stg-tp-column-reverse">
              <div className="stg-col-9" data-appear="fade-right" data-unload="fade-left">
                {/* Title */}
                <h1 className="bringer-page-title" data-split-appear="fade-up" data-split-unload="fade-up">Ensemble avançons avec confiance</h1>
                <div className="stg-top-gap">
                  <p className="bringer-large-text">Pour que votre <b className="jelam-special-word">PME, ETI</b> contrôle le changement.</p>
                  <p>Nous savons qu’à la tête de votre entreprise, chaque décision est cruciale. Le marché et la technologie évoluent vite, et il est normal de se sentir dépassé. Mais vous n'êtes pas seul.</p>
                  <p>Chez Jelam, nous vous aidons à transformer ces défis en opportunités. Avec nos méthodes d'innovation éprouvées, nous vous accompagnons pour anticiper, agir et développer les produits qui feront de vous un leader.</p>
                  <p>Ensemble, créons les changements qui comptent et définissons les règles du jeu.</p>
                </div>
              </div>
              <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-unload="fade-right" data-appear="fade-left">
                <div className="bringer-parallax-media">
                  <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/pme_innovation.jpg" alt="Innovation" width="360" height="640" />
                </div>
              </div>
            </div>{/* .stg-row */}
          </div>{/* .bringer-hero-block */}
        </section>

        {/* Section: Steps */}
        <section className="backlight-top">
          <div className="stg-bottom-gap-l">
            <h2 data-appear="fade-up" data-unload="fade-up">Où en êtes-vous Aujourd'hui ?</h2>
          </div>
          {/* Step 01 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
            <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/growth.jpg" alt="Bonne idée" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
              <p className='bringer-large-text'>Vous aspirez à une croissance continue ?</p>
              <p>Nous comprenons qu'il est difficile d’avoir une vision claire de l’évolution de l’entreprise lorsque vous êtes seul(e) et constamment confronté(e) à des tâches opérationnelles quotidiennes.</p>
            </div>
          </div>
          {/* Step 02 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
            <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/customer.jpg" alt="Être le premier" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
              <p className='bringer-large-text'>Vous voulez trouver de nouveaux clients ?</p>
              <p>Nous savons que répondre aux attentes croissantes des clients et en attirer de nouveaux peut être un véritable défi. Les technologies évoluent rapidement, et il est difficile de rester à jour quand le temps, les compétences ou les moyens internes manquent.</p>
            </div>
            <div className="stg-col-3">{/* Empty Column */}</div>
          </div>
          {/* Step 03 Row */}
          <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
            <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/competitive.jpg" alt="Du soutien" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
              <p className='bringer-large-text'>Vous voulez vous différencier ?</p>
              <p>Nous comprenons la pression constante pour rester en tête dans un marché concurrentiel. La complexité croissante des technologies et l'agressivité des concurrents peuvent être épuisantes.</p>
            </div>
          </div>
          {/* Step 04 Row */}
          <div className="stg-row stg-valign-middle stg-tp-row-reverse" data-unload="fade-right">
            <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
              <div className="bringer-parallax-media">
                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/talent.jpg" alt="Changer le monde" width="960" height="960" />
              </div>
            </div>
            <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
              <p className='bringer-large-text'>Vous voulez fidéliser et attirer les talents ?</p>
              <p>Nous savons à quel point il est crucial de créer un environnement qui inspire et engage vos collaborateurs. Attirer et retenir les meilleurs talents est devenu plus complexe que jamais.</p>
            </div>
            <div className="stg-col-3">{/* Empty Column */}</div>
          </div>
        </section>

        {/* Section: Services */}
        <section className="backlight-bottom">
          {/* Section Title */}
          <div className="stg-row bringer-section-title">
            <div className="stg-col-8 stg-offset-2">
              <div className="align-center">
                <h2 data-appear="fade-up" data-unload="fade-up">Ensemble, nous surmontons les obstacles</h2>
              </div>
            </div>
          </div>
          {/* List with Preview */}
          <div className="bringer-list-with-preview" data-preview-position="left" data-unload="fade-up" data-delay="200">
            <div className="bringer-lwp-roster">
              {/* Item 01 */}
              <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                <img src="assets/img/inner-pages/relais.jpg" alt="Copilotage" width="800" height="1200" />
                <div className="bringer-lwp-item-content">
                  <h5>Copilotons, déléguez tout en restant vous-même<span className="bringer-accent">.</span></h5>
                  <ul>
                    <li>
                      Nous apprenons à connaître les spécificités de votre entreprise.
                    </li>
                    <li>
                      Nous comprenons vos enjeux et offrons des solutions sur mesure.
                    </li>
                    <li>
                      Nous analysons vos contraintes.
                    </li>
                    <li>
                      Nous découvrons vos clients et écoutons les utilisateurs de vos produits.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Item 02 */}
              <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                <img src="assets/img/inner-pages/teamwork.jpg" alt="Ensemble en équipe" width="800" height="1200" />
                <div className="bringer-lwp-item-content">
                  <h5>Prenons du recul pour vous différencier<span className="bringer-accent">.</span></h5>
                  <ul>
                    <li>
                      Nous analysons vos forces et vos axes de développement.
                    </li>
                    <li>
                      Nous rencontrons vos équipes pour saisir leur potentiel.
                    </li>
                    <li>
                      Nous planifions et rendons agile la réalisation.
                    </li>
                    <li>
                      Nous écoutons, structurons et faisons émerger les idées novatrices.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Item 03 */}
              <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                <img src="assets/img/inner-pages/stepbystep.jpg" alt="Processus" width="800" height="1200" />
                <div className="bringer-lwp-item-content">
                  <h5>Avançons pas à pas et maximisons vos chances<span className="bringer-accent">.</span></h5>
                  <ul>
                    <li>
                      Nous fédérons les parties prenantes autour d’une vision commune.
                    </li>
                    <li>
                      Nous réalisons des prototypes de vos idées pour tester leur pertinence.
                    </li>
                    <li>
                      Nous planifions pour assurer une progression cohérente et efficace.
                    </li>
                    <li>
                      Nous vous aidons à faire des choix structurants.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Item 04 */}
              <div className="bringer-lwp-item bringer-block" data-appear="fade-left">
                <img src="assets/img/inner-pages/defi.jpg" alt="Défi" width="800" height="1200" />
                <div className="bringer-lwp-item-content">
                  <h5>Défions le statut quo et influençons votre marché<span className="bringer-accent">.</span></h5>
                  <ul>
                    <li>
                      Nous développons des solutions innovantes pour influencer les besoins du marché.
                    </li>
                    <li>
                      Nous lançons des produits qui se démarquent.
                    </li>
                    <li>
                      Nous organisons une veille stratégique.
                    </li>
                    <li>
                      Nous mesurons les résultats en temps réel et adaptons la stratégie.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Learn More */}
              <div className="align-right">
                <a href="/productstudio" className="bringer-arrow-link">Découvrez le Product Studio</a>
              </div>
            </div>
          </div>{/* .bringer-list-with-preview */}
        </section>


        {/* Section: What Makes Us Best */}
        <section className="backlight-bottom">
          {/* Section Title */}
          <div className="stg-row bringer-section-title">
            <div className="stg-col-8 stg-offset-2">
              <div className="align-center">
                <h2 data-appear="fade-up" data-unload="fade-up">Nous avons la culture de l’innovation</h2>
                <p data-appear="fade-up" data-unload="fade-up" data-delay="100">Nous avons une expérience solide en innovation et en technologie numérique, nous sommes des experts en stratégie de croissance. Nous avons l’agilité des Startups et nous maîtrisons les exigences liés à ce type d’activité.</p>
              </div>
            </div>
          </div>
          {/* Content Grid */}
          <div className="bringer-grid-2cols bringer-tp-grid-1col" data-stagger-appear="zoom-in" data-stagger-delay="100" data-stagger-unload="fade-up">
            {/* Card Item 01 */}
            <div className="bringer-block">
              <h4>Développement de la créativité</h4>
              <h6><span className="bringer-accent">Ensemble, révélons votre créativité cachée.</span></h6>
              <p>Chaque entreprise a un potentiel créatif inexploité. En réveillant l’ingéniosité de votre équipe, nous co-créons des solutions uniques qui non seulement répondent aux défis du marché et renforcent votre identité.</p>
            </div>{/* .bringer-block */}

            {/* Card Item 02 */}
            <div className="bringer-block">
              <h4>Processus d'innovation</h4>
              <h6><span className="bringer-accent">Structurons votre innovation.</span></h6>
              <p>L’innovation, c’est dans notre ADN. Nous vous aidons à structurer vos idées pour qu’elles deviennent des produits concrets, vous permettant de rester en tête de l’innovation.</p>
            </div>{/* .bringer-block */}

            {/* Card Item 03 */}
            <div className="bringer-block">
              <h4>Stratégie de croissance</h4>
              <h6><span className="bringer-accent">Construisons une innovation profitable.</span></h6>
              <p>Votre croissance est notre mission. Nous vous accompagnons pour identifier les meilleures opportunités et élaborer des stratégies sur mesure.</p>
            </div>{/* .bringer-block */}

            {/* Card Item 04 */}
            <div className="bringer-block">
              <h4>Transformation numérique</h4>
              <h6><span className="bringer-accent">Révolutionnons votre avenir numérique.</span></h6>
              <p>La transformation numérique est plus qu’une évolution, c’est une révolution. Nous vous guidons pas à pas pour développer de nouvelles gammes d'offre grâce aux technologies innovantes.</p>
            </div>{/* .bringer-block */}
          </div>
        </section>

        {/* Section: Marquee */}
        <section className="is-fullwidth is-stretched" data-padding="none">
          <div className="align-center stg-bottom-gap-l">
            <h2 data-appear="fade-up" data-unload="fade-up">Et voici ce que nous et nos partenaires maîtrisons</h2>
          </div>
          <div className="bringer-marquee stg-bottom-gap-l" data-speed="5000">
            <div className="bringer-marquee-inner">
              <ul className="bringer-marquee-list">
                <li>
                  <h2>Conseil en innovation</h2>
                </li>
                <li>
                  <h2>Application industrielle</h2>
                </li>
                <li>
                  <h2>Design Sprint</h2>
                </li>
                <li>
                  <h2>Cybersécurité</h2>
                </li>
                <li>
                  <h2>Technologie innovante</h2>
                </li>
              </ul>
            </div>{/* .bringer-marquee-inner */}
          </div>{/* .bringer-marquee */}
        </section>

        {/* Section: CTA Form */}
        <section data-padding="bottom">
          <div className="bringer-masked-cta bringer-masked-block" data-unload="fade-down">
            <form onSubmit={handleSubmit} method="post" data-fill-error="Veuillez compléter les champs obligatoires." className="bringer-contact-form is-short bringer-masked-media" data-appear="fade-up">
              <div className="bringer-form-content bringer-cta-form">
                <div className="bringer-cta-form-content" data-appear="fade-up" data-delay="100">
                  <div className="bringer-cta-title">Discutons de votre prochain produit.</div>
                  <input type="text" id="fullname" name="fullname" placeholder="Votre nom" required value={fullname} onChange={(e) => setFullName(e.target.value)} />
                  <input type="tel" pattern="^(\+33|0)[1-9](\d{2}){4}$" id="phoneNumber" name="phoneNumber" placeholder="Votre numéro" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <div className="bringer-cta-form-button" data-appear="fade-up" data-delay="200">
                  <button type="submit">
                    <span className="bringer-icon bringer-icon-arrow-submit"></span>
                  </button>
                </div>
                <div className="bringer-contact-form__response">{response}</div>
              </div>
              <span className="bringer-form-spinner"></span>
            </form>
            <div className="bringer-masked-cta-content bringer-masked-content at-top-right">
              <p className="bringer-large-text" data-appear="fade-down">Échangeons sur votre projet. ✨</p>
            </div>
          </div>
        </section>
      </div>
    </>

  )
}

export default Company