import React from 'react'
import Aboutus from './pages/aboutus'
import Cgu from './pages/cgu'
import Contact from './pages/contact'
import Company from './pages/company'
import Founder from './pages/founder'
import Escapad from './pages/escapad'
import Home from './pages/home'
import Portfolio from './pages/portfolio'
import Privacy from './pages/privacy'
import Productstudio from './pages/productstudio'
import Qstom from './pages/qstom'
import Startup from './pages/startup'
import Startupstudio from './pages/startupstudio'
import Header from './components/Header'
import Footer from './components/Footer'

import { Suspense, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

function App() {

  useEffect(() => {
    loadscript("assets/js/contact_form.js");
    loadscript("assets/js/st-core.js");
    loadscript("assets/js/classes.js");
    loadscript("assets/js/main.js");
  }, [])

  return (
    <Suspense fallback={<></>}>
      <HelmetProvider>
        <Header />  
          <main id="bringer-main">
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<Home/>} />
                  <Route path='/aboutus' element={<Aboutus/>} />
                  <Route path='/cgu' element={<Cgu/>} />
                  <Route path='/contact' element={<Contact/>} />
                  <Route path='/company' element={<Company/>} />
                  <Route path='/founder' element={<Founder/>} />
                  <Route path='/escapad' element={<Escapad/>} />
                  <Route path='/portfolio' element={<Portfolio/>} />
                  <Route path='/privacy' element={<Privacy/>} />
                  <Route path='/productstudio' element={<Productstudio/>} />
                  <Route path='/qstom' element={<Qstom/>} />
                  <Route path='/startup' element={<Startup/>} />
                  <Route path='/startupstudio' element={<Startupstudio/>} />
                </Routes>
              </BrowserRouter>
              <Footer />
          </main>
        </HelmetProvider>
    </Suspense>
  )
}

function loadscript(url) {

  var script = document.createElement("script")
  script.type = "text/javascript"
  script.src = url

  document.getElementsByTagName("body")[0].appendChild(script)
}

export default App
