import React from 'react'

function Header() {
    return (
        <div>
            {/* Header */}
            <header id="bringer-header" className="is-frosted is-sticky" data-appear="fade-down" data-unload="fade-up">
                {/* Desktop Header */}
                <div className="bringer-header-inner">
                    {/* Header Logo */}
                    <div className="bringer-header-lp">
                        <a href="/" className="bringer-logo">
                            <img src="assets/img/logo.png" alt="Jelam" width="88" height="24" />
                        </a>
                    </div>
                    {/* Main Menu */}
                    <div className="bringer-header-mp">
                        <nav className="bringer-nav">
                            <ul className="main-menu" data-stagger-appear="fade-down" data-stagger-delay="75">
                                {/* <li className="current-menu-parent"> */}
                                <li>
                                    <a href="/">Accueil</a>
                                </li>
                                <li>
                                    <a href="#">Vous êtes</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <a href="/startup">Startup</a>
                                        </li>
                                        <li>
                                            <a href="/company">PME, ETI</a>
                                        </li>
                                        <li>
                                            <a href="/founder">Fondateur</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Nos Studios</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <a href="/startupstudio">Startup Studio</a>
                                        </li>
                                        <li>
                                            <a href="/productstudio">Product Studio</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Qui sommes-nous ?</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <a href="/aboutus">L'équipe</a>
                                        </li>
                                        <li>
                                            <a href="/portfolio">Nos réalisations</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* Header Button */}

                    <div className="bringer-header-rp">
                        <a href="/contact" className="bringer-button">Échangeons sur votre idée</a>
                    </div>
                    <ul className="bringer-socials-list" data-stagger-appear="fade-up" data-stagger-delay="75">
                        <li>
                            <a href="https://www.linkedin.com/company/jelamfr" target="_blank" rel="noreferrer" className="bringer-socials-linkedin">
                                <i></i>
                            </a>
                        </li>
                    </ul>

                </div>
                {/* Mobile Header */}
                <div className="bringer-mobile-header-inner">
                    <a href="/" className="bringer-logo">
                        <img src="assets/img/logo.png" alt="Jelam." width="88" height="24" />
                    </a>
                    <div className="bringer-header-rp">
                        <a href="/contact" className="bringer-button">Échangeons sur votre idée</a>
                    </div>
                    <a href="#" className="bringer-mobile-menu-toggler">
                        <i className="bringer-menu-toggler-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </i>
                    </a>
                </div>
            </header>
        </div>
    )
}

export default Header