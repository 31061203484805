import React from 'react'
import MetaTags from '../components/MetaTags';

function Portfolio() {
    return (
        <>
            <MetaTags
                title='Nos réalisations'
                description="Découvrez nos réalisations innovantes : des produits développés avec passion et expertise, du concept à la réussite commerciale."
                image='assets/img/favicon.png'
                name='Jelam'
            />
            <div className='stg-container'>
                {/* Section: Page Title */}
                <section className="backlight-bottom">
                    <div className="stg-row">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h1 className="bringer-page-title" data-appear="fade-up" data-unload="fade-up">Nos réalisations</h1>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section: Portfolio Listing */}
                <section>

                    {/* Portfolio Card Item 01 */}
                    <div className="bringer-column-item bringer-masked-block" data-unload="fade-up">
                        {/* Media */}
                        <div className="bringer-masked-media bringer-parallax-media" data-appear="fade-up" data-threshold="0">
                            <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/couple_video_qstom.jpg" alt="Notre Startup QSTOM" width="1920" height="1280" />
                        </div>
                        {/* Content */}
                        <div className="bringer-masked-content at-bottom-center">
                            <div className="bringer-column-item-content">
                                <div className="bringer-column-item-title">
                                    <span className="bringer-meta">Startup Studio</span>
                                    <h3>QSTOM</h3>
                                </div>
                                <div className="bringer-icon-wrap">
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="/qstom"></a>
                            </div>
                        </div>
                    </div>

                    {/* Portfolio Card Item 02 */}
                    <div className="bringer-column-item bringer-masked-block" data-unload="fade-up">
                        {/* Media */}
                        <div className="bringer-masked-media bringer-parallax-media" data-appear="fade-up" data-threshold="0">
                            <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/portfolio/telephone_balade_GC.jpg" alt="Escape Game à Fontainebleau" width="1920" height="1280" />
                        </div>
                        {/* Content */}
                        <div className="bringer-masked-content at-bottom-center">
                            <div className="bringer-column-item-content">
                                <div className="bringer-column-item-title">
                                    <span className="bringer-meta">Product Studio</span>
                                    <h3>Escapad</h3>
                                </div>
                                <div className="bringer-icon-wrap">
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                </div>
                                {/* eslint-disable-next-line */}
                                <a href="/escapad"></a>
                            </div>
                        </div>
                    </div>

                </section>

                {/* Section: Grid CTA */}
                <section className="backlight-top">
                    <div className="bringer-bento-grid bringer-grid-cta">
                        <div className="is-large bringer-masked-block" data-appear="fade-right" data-unload="fade-left">
                            <div className="bringer-grid-cta-media bringer-masked-media" data-bg-src="assets/img/cta/next.jpg">
                                <div className="bringer-grid-cta-heading">Envie d'être le prochain ?</div>
                            </div>
                            <div className="bringer-masked-content at-bottom-right">
                                <p className="bringer-large-text" data-appear="fade-right" data-delay="100">Échangeons sur votre idée &rarr;</p>
                            </div>
                        </div>
                        <div className="is-medium bringer-block" data-appear="fade-down" data-unload="fade-right">
                            <h3>Ensemble rendons vos idées tangibles et profitables</h3>
                        </div>
                        <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
                            <a href="/contact" className="bringer-square-button">
                                <span className="bringer-icon bringer-icon-explore"></span>
                            </a>
                        </div>
                        <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
                            <img src="assets/img/cta/next2.jpg" alt="Discutons de votre idée" width="960" height="960" />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Portfolio