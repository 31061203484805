import React from 'react'
import MetaTags from '../components/MetaTags';

function Productstudio() {
    return (
        <>
            <MetaTags
                title='Bienvenue dans notre Product Studio'
                description='Placez l’innovation au cœur de votre stratégie. Ensemble, assurons le succès commercial de vos produits grâce à notre expertise en idéation, développement et lancement.'
                image='assets/img/inner-pages/product-studio.jpg'
                name='Jelam'
            />
            <div className='stg-container'>
                {/* Section: Intro */}
                <section>
                    <div className="align-center stg-bottom-gap">
                        <h1 className="bringer-page-title" data-appear="fade-up" data-unload="fade-up">Notre Product Studio</h1>
                    </div>
                    {/* Section Image */}
                    <div className="bringer-parallax-media stg-bottom-gap-l" data-appear="fade-up" data-unload="fade-up">
                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/product-studio.jpg" alt="Product Studio" width="1920" height="960" />
                    </div>

                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap">
                        <div className="stg-col-6" data-unload="fade-left">
                            <h2 className="stg-bottom-gap" data-split-appear="fade-up">Lançons des produits impactants ✨ .</h2>
                        </div>
                        <div className="stg-col-6"></div>
                    </div>

                    {/* Section Content */}
                    <div className="stg-row stg-tp-column-reverse">
                        <div className="stg-col-6 stg-valign-bottom" data-unload="fade-left">
                            <div className="align-right">
                                <a href="/contact" className="bringer-icon-link" data-appear="fade-right">
                                    <div className="bringer-icon-link-content">
                                        <h6>
                                            Libérons
                                            <br />
                                            votre potentiel
                                        </h6>
                                        <span className="bringer-label">Dépassez vos limites</span>
                                    </div>
                                    <div className="bringer-icon-wrap">
                                        <i className="bringer-icon bringer-icon-explore"></i>
                                    </div>
                                </a>{/* .bringer-icon-link */}
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-9 stg-tp-bottom-gap" data-appear="fade-left" data-unload="fade-right">
                            <p className="bringer-large-text">Nous propulsons vos idées vers le succès.</p>
                        </div>
                    </div>
                </section>


                {/* Section: Portfolio List */}
                <section>
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Qu'est-ce qui nous rend unique ?</h2>
                                <p className="bringer-large-text" data-appear="fade-up" data-unload="fade-up" data-delay="100">Nous mettons notre bienveillance, empathie et ouverture d’esprit au cœur de chaque projet, assurant un accompagnement chaleureux et humain. Ensemble nous développons la valeur produite et transformons des idées en produits concrets, allant au-delà de simples solutions numériques.</p>
                            </div>
                        </div>
                    </div>
                    {/* Portfolio List */}
                    <div className="stg-row stg-valign-middle stg-tp-column-reverse">
                        <div className="stg-col-6" data-unload="fade-left" data-delay="100">
                            <div className="bringer-grid-1col">


                                {/* Portfolio List Item */}
                                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="300">
                                    <div className="bringer-portfolio-thmb">
                                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/ecoutepme.jpg" alt="A votre écoute" width="1200" height="1200" />
                                    </div>
                                    <div className="bringer-portfolio-short-title">
                                        <span className="bringer-meta">Écoute</span>
                                        <p>Des <span className="bringer-accent">solution</span> personnalisées à votre contexte<span className="bringer-accent">.</span></p>
                                    </div>
                                </div>{/* .bringer-portfolio-short-item */}

                                {/* Portfolio List Item */}
                                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="100">
                                    <div className="bringer-portfolio-thmb">
                                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/engagementpme.jpg" alt="Engagement" width="1200" height="1200" />
                                    </div>
                                    <div className="bringer-portfolio-short-title">
                                        <span className="bringer-meta">Engagement</span>
                                        <p>Un <span className="bringer-accent">engagement constant</span> à vos côtés<span className="bringer-accent">.</span></p>
                                    </div>
                                </div>{/* .bringer-portfolio-short-item */}

                                {/* Portfolio List Item */}
                                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="200">
                                    <div className="bringer-portfolio-thmb">
                                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/xppme.jpg" alt="Expérience de l'innovation" width="1200" height="1200" />
                                    </div>
                                    <div className="bringer-portfolio-short-title">
                                        <span className="bringer-meta">Expérience</span>
                                        <p>Une <span className="bringer-accent">méthodologie structurée</span> et éprouvée<span className="bringer-accent">.</span></p>
                                    </div>
                                </div>{/* .bringer-portfolio-short-item */}

                                {/* Portfolio List Item */}
                                <div className="bringer-block bringer-portfolio-short-item" data-appear="fade-right" data-delay="0">
                                    <div className="bringer-portfolio-thmb">
                                        <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/enthousiame.jpg" alt="Enthousiasme" width="1200" height="1200" />
                                    </div>
                                    <div className="bringer-portfolio-short-title">
                                        <span className="bringer-meta">Enthousiasme</span>
                                        <p>Une <span className="bringer-accent">passion</span> tournée vers l'innovation<span className="bringer-accent">.</span></p>
                                    </div>
                                </div>{/* .bringer-portfolio-short-item */}

                                {/* Learn More */}
                                <div className="bringer-portfolio-short-item bringer-portfolio-short-more" data-appear="fade-right" data-delay="400">
                                    <div className="bringer-portfolio-short-title">
                                        <span className="bringer-label">Prêt à booster votre produit.</span>
                                        <h6>Lancez-vous</h6>
                                    </div>
                                    <span className="bringer-icon bringer-icon-explore"></span>
                                    {/* eslint-disable-next-line */}
                                    <a href="/contact"></a>
                                </div>{/* .bringer-portfolio-short-more */}
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-bottom-gap" data-unload="fade-right" data-delay="200">
                            <div className="bringer-parallax-media" data-appear="fade-left">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/inner-pages/whypme.jpg" alt="Pourquoi nous choisir ?" width="960" height="1095" />
                            </div>
                        </div>
                    </div>
                </section>


                {/* Section: Why Choose Us */}
                <section>
                    <div className="stg-row stg-large-gap">
                        <div className="stg-col-6 stg-tp-bottom-gap-l" data-unload="fade-left">
                            <div className="bringer-sticky-block">
                                <h2>Où en êtes-vous ?</h2>
                                <p className="bringer-large-text">Nous vous sommes à vos côtés à chaque étape.</p>
                                <div className="align-right">
                                    <a href="/contact" className="bringer-arrow-link">Commençons maintenant</a>
                                </div>
                            </div>
                        </div>
                        <div className="stg-col-6">
                            <div className="bringer-grid-1col stg-normal-gap" data-stagger-appear="zoom-in" data-stagger-unload="zoom-out" data-threshold="0.5" data-stagger-delay="150">
                                {/* Card Item */}
                                <div className="bringer-block">
                                    <h4>J'ai une idée et je souhaite <span className="bringer-accent">approfondir son potentiel.</span></h4>
                                    <p>Vous avez une idée ambitieuse, mais vous ne savez pas comment approfondir son potentiel et définir la feuille de route.</p>
                                    <h6>Nous vous accompagnons</h6>
                                    <p>Grâce au Design Thinking et autres méthodes d'innovation, nous sondons en profondeur votre idée. Nous mettons en lumière ses points forts, découvrons ce qui la distingue, et identifions les besoins du marché pour vous guider vers les meilleures stratégies de mise en œuvre.</p>
                                    {/* <ul>
                                        <li>
                                            Faisons briller la singularité de votre idée.
                                        </li>
                                        <li>
                                            Concentrons les efforts de manière optimale.
                                        </li>
                                        <li>
                                            Optimisons vos chances de conquérir le marché.
                                        </li>
                                    </ul> */}
                                </div>{/* .bringer-block */}

                                {/* Card Item */}
                                <div className="bringer-block">
                                    <h4>Aidez-moi à <span className="bringer-accent">tester la valeur</span> de mon produit sur le marché.</h4>
                                    <p>Votre concept est prêt, mais vous devez vous assurer qu’il répond aux attentes du marché avant son lancement.</p>
                                    <h6>Nous vous aidons</h6>
                                    <p>En intégrant les principes du Design Thinking et de l’UX/UI Design, nous créons des prototypes que nous testons directement avec vos utilisateurs cibles. Les retours rapides permettent de peaufiner votre concept, garantissant ainsi sa pertinence et sa capacité à captiver le marché.</p>
                                    {/* <ul>
                                        <li>
                                            Limitons les risques de lancement.
                                        </li>
                                        <li>
                                            Gagnons du temps.
                                        </li>
                                        <li>
                                            Assurons-nous que votre concept résonne avec vos utilisateurs
                                        </li>
                                    </ul> */}
                                </div>{/* .bringer-block */}

                                {/* Card Item */}
                                <div className="bringer-block">
                                    <h4>J'aimerais <span className="bringer-accent">concrétiser et lancer</span> un produit.</h4>
                                    <p>Votre idée est validée, et vous êtes prêt à passer à l'action pour la concrétiser, levée des fonds et la lancer sur le marché.</p>
                                    <h6>Nous vous guidons</h6>
                                    <p>Nous transformons votre vision en un produit prêt à conquérir le marché. En utilisant l’UX/UI Design et les méthodes Agiles, nous assurons un développement rapide et fluide. Nous vous accompagnons aussi dans la stratégie Go To Market pour un lancement percutant qui maximise votre impact dès le premier jour.</p>
                                    {/* <ul>
                                        <li>
                                            Lançons un produit abouti qui capte l’attention.
                                        </li>
                                        <li>
                                            Garantissons un lancement fort et mémorable.
                                        </li>
                                        <li>
                                            Saisissons toutes les chances de succès commercial.
                                        </li>
                                    </ul> */}
                                </div>{/* .bringer-block */}

                                {/* Card Item */}
                                <div className="bringer-block">
                                    <h4>Je souhaite <span className="bringer-accent">structurer un projet</span> en cours<span className="bringer-accent">.</span></h4>
                                    <p>Vous avez une vision claire, mais vous avez besoin de structurer votre projet et de définir des objectifs précis pour progresser sereinement.</p>
                                    <h6>Nous vous conseillons</h6>
                                    <p>Grâce aux méthodes de développement Agile, nous copilotons, établissons un cadre flexible et réactif pour votre projet. Ensemble, nous clarifions les attentes, affinons les besoins utilisateurs et définissons des objectifs concrets pour faire avancer votre projet à chaque étape.</p>
                                    {/* <ul>
                                        <li>
                                            Progressons avec une feuille de route agile et adaptable.
                                        </li>
                                        <li>
                                            Exploitons vos ressources de manière optimale.
                                        </li>
                                        <li>
                                            Réduisons les imprévus et restons sur la bonne voie.
                                        </li>
                                    </ul> */}
                                </div>{/* .bringer-block */}

                                {/* Card Item */}
                                <div className="bringer-block">
                                    <h4>J'aimerais <span className="bringer-accent">apprendre à innover.</span></h4>
                                    <p>Votre entreprise se développe, mais trouver des idées novatrices pour aller encore plus loin semble complexe. Vous souhaitez libérer le potentiel créatif de vos équipes pour faire évoluer votre activité.</p>
                                    <h6>Nous vous stimulons</h6>
                                    <p>Nos ateliers interactifs vous incitent à penser autrement, à explorer de nouvelles perspectives, et à intégrer l’UX/UI Design dès le départ pour transformer vos idées en expériences captivantes.</p>
                                    {/* <ul>
                                        <li>
                                            Déverrouillons la créativité de vos équipes.
                                        </li>
                                        <li>
                                            Décelons des opportunités inédites.
                                        </li>
                                        <li>
                                            Installons une culture d’innovation durable et dynamique.
                                        </li>
                                    </ul> */}
                                </div>{/* .bringer-block */}
                            </div>{/* .bringer-grid */}
                        </div>
                    </div>
                </section>
                {/* Section: Prices */}
                <section className="divider-both tp-is-fullwidth tp-is-stretched">
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap" data-unload="fade-up">
                        <div className="stg-col-8 stg-offset-2 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up">Amenons vos produits au niveau supérieur !</h2>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row stg-bottom-gap-l" data-unload="fade-up" data-delay="100">
                        <div className="stg-col-6 stg-offset-3 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100">
                                    Parcourez nos <span className="jelam-special-word">5 plans</span> pour faire briller votre innovation
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Price Table Carousel */}
                    <div className="swiper bringer-carousel" data-appear="fade-up" data-delay="200" data-count="2.5" data-tp-count="2" data-unload="fade-up">
                        <div className="swiper-wrapper">
                            {/* Carousel Item 01 */}
                            <div className="bringer-block bringer-price-table swiper-slide">
                                <h6>Conception de produit<span className="bringer-accent">.</span></h6>
                                <p>Développer et lancer un produit numérique sur mesure.</p>
                                <div className="bringer-price-wrapper">
                                    <div className="bringer-label">Offre</div>
                                    <h2>Sur Mesure</h2>
                                </div>
                                <div className="bringer-label bringer-highlight">Ce qui est inclus</div>
                                <ul className="bringer-marked-list">
                                    <li>Vision Produit</li>
                                    <li>Design UX/UI complet</li>
                                    <li>Produit numérique fonctionnel</li>
                                    <li>Stratégie de lancement</li>
                                </ul>
                                <a href="/contact" className="bringer-button is-fullwidth">Commander maintenant</a>
                            </div>{/* .bringer-carousel-card */}

                            {/* Carousel Item 02 */}
                            <div className="bringer-block bringer-price-table swiper-slide">
                                <h6>Prototypage<span className="bringer-accent">.</span></h6>
                                <p>Concevoir et tester des prototypes pour valider votre idée et prouver sa valeur.</p>
                                <div className="bringer-price-wrapper">
                                    <div className="bringer-label">À partir de</div>
                                    <h2>10,000 €</h2>
                                </div>
                                <div className="bringer-label bringer-highlight">Ce qui est inclus</div>
                                <ul className="bringer-marked-list">
                                    <li>Maquettes fonctionnelles</li>
                                    <li>Plan de tests de marché</li>
                                    <li>Résultats d'analyse</li>
                                    <li>Itérations de prototype</li>
                                </ul>
                                <a href="/contact" className="bringer-button is-fullwidth">Commander maintenant</a>
                            </div>{/* .bringer-carousel-card */}

                            {/* Carousel Item 03 */}
                            <div className="bringer-block bringer-price-table swiper-slide">
                                <h6>Design Sprint<span className="bringer-accent">.</span></h6>
                                <p>Un atelier intensif de 5 jours pour prototyper et tester rapidement vos idées.</p>
                                <div className="bringer-price-wrapper">
                                    <div className="bringer-label">À partir de</div>
                                    <h2>5,000 €</h2>
                                </div>
                                <div className="bringer-label bringer-highlight">Ce qui est inclus</div>
                                <ul className="bringer-marked-list">
                                    <li>Organisation et animation</li>
                                    <li>Ciblage de la problèmatique</li>
                                    <li>Prototypes cliquables</li>
                                    <li>Plan et Guide d'amélioration</li>
                                </ul>
                                <a href="/contact" className="bringer-button is-fullwidth">Commander maintenant</a>
                            </div>{/* .bringer-carousel-card */}

                            {/* Carousel Item 04 */}
                            <div className="bringer-block bringer-price-table swiper-slide">
                                <h6>Co-Pilotage Stratégique<span className="bringer-accent">.</span></h6>
                                <p>Accompagnement stratégique sur mesure pour structurer votre croissance.</p>
                                <div className="bringer-price-wrapper">
                                    <div className="bringer-label">Offre</div>
                                    <h2>Sur Mesure</h2>
                                </div>
                                <div className="bringer-label bringer-highlight">Ce qui est inclus</div>
                                <ul className="bringer-marked-list">
                                    <li>Stratégie de lancement personnalisée</li>
                                    <li>CTO à temps partagé</li>
                                    <li>Vision Produit</li>
                                    <li>Support DAF</li>
                                </ul>
                                <a href="/contact" className="bringer-button is-fullwidth">Commander maintenant</a>
                            </div>{/* .bringer-carousel-card */}

                            {/* Carousel Item 05 */}
                            <div className="bringer-block bringer-price-table swiper-slide">
                                <h6>Innovation numérique<span className="bringer-accent">.</span></h6>
                                <p>Mise en place de l'innovation stratégique au sein de votre entreprise.</p>
                                <div className="bringer-price-wrapper">
                                <div className="bringer-label">Packs</div>
                                <h2>Au forfait</h2>
                                </div>
                                <div className="bringer-label bringer-highlight">Ce qui est inclus</div>
                                <ul className="bringer-marked-list">
                                    <li>Diagnostic d'innovation</li>
                                    <li>Accompagnement des équipes</li>
                                    <li>Stratégie d'innovation continue</li>
                                    <li>Formation et mise en œuvre</li>
                                </ul>
                                <a href="/contact" className="bringer-button is-fullwidth">Commander maintenant</a>
                            </div>{/* .bringer-carousel-card */}

                        </div>
                        {/* Pagination */}
                        <div className="swiper-pagination bringer-dots"></div>
                    </div>{/* .bringer-carousel */}
                </section>

                {/* Section: Our Story */}
                <section className="backlight-top">
                    <div className="stg-row stg-large-gap stg-m-normal-gap">
                        <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-appear="fade-right" data-unload="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="assets/img/null.png" data-src="assets/img/partners/logo_Globe_Climber.jpg" alt="Projet innovant" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-vertical-space-between" data-appear="fade-left" data-unload="fade-right">
                            <div>
                                <h2>Découvrez l'histoire de l'Escape Game Globe Climber</h2>
                                <p className="bringer-large-text">Sur fond de transformation numérique, découvrez les étapes de la réalisation de l’Escape Game Globe Climber.</p>
                                <p>L'Escape Game "À la recherche du Bloc Ultime" est une aventure en extérieur vous guidant dans un parcours exceptionnel dans la fôret de Fontainebleau.</p>
                            </div>
                            <a href="/escapad" className="bringer-icon-link">
                                <div className="bringer-icon-wrap">
                                    <i className="bringer-icon bringer-icon-explore"></i>
                                </div>
                                <div className="bringer-icon-link-content">
                                    <h6>
                                        Envie d'en
                                        <br />
                                        Savoir plus
                                    </h6>
                                    <span className="bringer-label">Le Projet Globe Climber</span>
                                </div>
                            </a>{/* .bringer-icon-link */}
                        </div>
                    </div>
                </section>

                {/* Section: FAQ */}
                <section>
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap-l">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up" data-unload="fade-up">Vous avez encore des questions ?</h2>
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Voici quelques questions qui reviennent fréquemment</p>
                            </div>
                        </div>
                    </div>
                    {/* FAQ List */}
                    <div className="bringer-faq-list">
                        {/* FAQ Item 01 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>01.</sup>
                                    Pourquoi choisir notre Product Studio ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Nous faisons plus que simplement développer des produits. Nous devenons vos partenaires de confiance, partageant votre vision et mettant à votre disposition nos années d’expertise en produit innovant. Ce qui nous distingue, c’est notre approche collaborative et transparente : nous travaillons main dans la main avec vous pour transformer vos idées en succès concrets, tout en protégeant vos intérêts.</p>
                            </div>
                        </div>
                        {/* FAQ Item 02 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>02.</sup>
                                    Comment savoir si mon idée est bonne et innovante ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Évaluer la validité et l'innovation de votre idée est la première étape cruciale vers son succès. Nous sommes parfaitement positionnés pour vous accompagner dans cette démarche. Grâce à notre expertise, nous vous aidons à analyser votre concept en profondeur, en tenant compte des besoins du marché, des tendances actuelles, et de la faisabilité technique. Nous utilisons des méthodes éprouvées, comme le design thinking et les tests utilisateurs, pour vous assurer que votre idée est à la fois innovante et porteuse de succès.</p>
                            </div>
                        </div>
                        {/* FAQ Item 03 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">Service</span>
                                <h4>
                                    <sup>03.</sup>
                                    Combien de temps faut-il pour développer un produit avec Jelam ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>En général, il faut compter entre 4 et 6 mois pour développer un produit avec Jelam. Ce délai peut varier en fonction de la complexité du projet et des besoins spécifiques, mais notre approche Agile nous permet d’avancer rapidement tout en gardant une grande flexibilité. Nous nous engageons à respecter des délais réalistes et à vous tenir informé à chaque étape pour que vous puissiez voir votre vision se concrétiser dans les meilleurs délais.</p>
                            </div>
                        </div>
                        {/* FAQ Item 04 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">Service</span>
                                <h4>
                                    <sup>04.</sup>
                                    Quels types de secteur d’activité peut bénéficier de vos services ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Tous les secteurs d'activité peuvent bénéficier des services de Jelam. Notre expérience couvre un large éventail d’industries, et notre méthodologie adaptable nous permet de répondre aux besoins spécifiques de chaque domaine. Que vous ayez identifié une technologie prometteuse pour votre activité, que vous soyez dans la santé, le loisir, la cybersécurité, ou tout autre secteur, nous apportons une approche innovante et personnalisée pour vous aider à relever vos défis et saisir vos opportunités.</p>
                            </div>
                        </div>
                        {/* FAQ Item 05 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">Service</span>
                                <h4>
                                    <sup>05.</sup>
                                    Accompagnez-vous également les activités de services ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Oui, nous accompagnons également les activités de services. Que vous soyez dans le secteur des services ou de la production, notre expertise s’adapte à vos spécificités. Nous comprenons les défis uniques des entreprises de services et mettons en place des solutions personnalisées pour vous aider à innover, structurer vos offres et développer votre activité.</p>
                            </div>
                        </div>
                        {/* FAQ Item 06 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">Financement</span>
                                <h4>
                                    <sup>06.</sup>
                                    Quel est le prix moyen d'un projet d'innovation ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Le prix d’un projet d'innovation chez Jelam dépend de sa complexité et de ses spécificités. Cependant, nous avons une approche claire : nous mettons en perspective nos prix avec la valeur produite pour que nos clients soient toujours gagnants. Notre objectif est de garantir que chaque euro investi génère un retour significatif, en maximisant la valeur ajoutée pour votre entreprise. Avec Jelam, vous avez la certitude d’un investissement judicieux, conçu pour offrir des résultats tangibles et durables.</p>
                            </div>
                        </div>
                        <hr />
                        {/* FAQ Item 07 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">Financement</span>
                                <h4>
                                    <sup>07.</sup>
                                    À quoi sert le crédit d'impôt innovation ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Le crédit d'impôt innovation (CII) permet aux entreprises de réduire leurs impôts en finançant une partie des dépenses liées au développement de nouveaux produits. En tant que Product Studio accrédité, Jelam vous aide à bénéficier de ce dispositif pour diminuer le coût de vos projets innovants, rendant ainsi vos investissements plus rentables.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section: Grid CTA */}
                <section>
                    <div className="bringer-bento-grid bringer-grid-cta">
                        <div className="is-large bringer-masked-block" data-appear="fade-right" data-unload="fade-left">
                            <div className="bringer-grid-cta-media bringer-masked-media" data-bg-src="assets/img/cta/nouveauproduit.jpg">
                                <div className="bringer-grid-cta-heading">Prêt à grandir ?</div>
                            </div>
                            <div className="bringer-masked-content at-bottom-right">
                                <p className="bringer-large-text" data-appear="fade-right" data-delay="100">Collaborons ensemble &rarr;</p>
                            </div>
                        </div>
                        <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
                            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                                <div>
                                    <h6>Vous avez une idée de</h6>
                                    <span className="jelam-special-word">nouveau produit</span><span className="bringer-accent">.</span>
                                </div>
                                <span>Nous vous aidons à la concrétiser.</span>
                            </div>
                        </div>
                        <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
                            <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                                <div>
                                    <h6>Vous cherchez à</h6>
                                    <span className="jelam-special-word">Innover</span><span className="bringer-accent">.</span>
                                </div>
                                <span>Nous créons une stratégie sur mesure.</span>
                            </div>
                        </div>

                        <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
                            <img src="assets/img/cta/designthinking.jpg" alt="Discutons" width="960" height="960" />
                        </div>
                        <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
                            <a href="/contact" className="bringer-square-button">
                                <span className="bringer-icon bringer-icon-explore"></span>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default Productstudio