import React from 'react'

function privacy() {
    return (
        <div className='stg-container'>
            <section className="overview">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-lg-offset-2">
                            <h1>Politique de confidentialité</h1>
                            <h2>ARTICLE 1 : Objet</h2>
                            <p>
                                Le site https://jelam.fr/ a pour vocation de permettre aux utilisateurs (« les Utilisateurs ») du site de prendre connaissance des différentes prestations proposées par la SAS JELAM et de prendre contact avec ladite société pour tout accompagnement lié à un projet innovant.
                            </p>
                            <p>
                                Les présentes Conditions Générales d'Utilisation (les « CGU ») définissent les conditions dans lesquelles la SAS JELAM met à la disposition des Utilisateurs les outils technologiques leur permettant de la contacter et disposer de ses services, par l'intermédiaire du site https://jelam.fr/.
                            </p>
                            <h2>ARTICLE 2 : Champ d'application</h2>
                            <p>
                                Les présentes CGU s'appliquent, sans restriction ni réserve à l'utilisation des services et outils technologiques mis à disposition des Utilisateurs par la SAS JELAM.
                            </p>
                            <p>
                                Ces outils permettent aux Utilisateurs d’accéder de contacter et de communiquer avec la SAS JELAM via le formulaire de contact et le formulaire d’inscription à la prochaine commission de sélection de projet innovant.
                            </p>
                            <p>
                                Les présentes CGU sont accessibles à tout moment sur le site Internet https://jelam.fr/ et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.
                            </p>
                            <p>
                                Ces CGU pouvant faire l'objet de modifications ultérieures.
                            </p>
                            <p>
                                Les modifications de ces CGU sont opposables aux utilisateurs du site Internet https://jelam.fr/ à compter de leur mise en ligne.
                            </p>
                            <h2>ARTICLE 3 : Accès et Navigation</h2>
                            <p>
                                La SAS JELAM Studio met en œuvre les solutions techniques à sa disposition pour permettre l’accès au site 24 heures sur 24, 7 jours sur 7. Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l’accès au site ou à certaines pages de celui-ci afin de procéder à des mises à jour, des modifications de son contenu ou tout autre action jugée nécessaire au bon fonctionnement du site.
                            </p>
                            <p>
                                La connexion et la navigation sur le site valent acceptation sans réserve des présentes Conditions Générales d’Utilisation, quelques soient les moyens techniques d’accès et les terminaux utilisés.
                            </p>
                            <p>
                                Les présentes CGU s’appliquent, en tant que de besoin, à toute déclinaison ou extension du site sur les réseaux sociaux et/ou communautaires existants ou à venir.
                            </p>
                            <h2>ARTICLE 4 : Gestion du site</h2>
                            <p>
                                Pour la bonne gestion du site, la SAS JELAM Studio pourra à tout moment :
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        Suspendre, interrompre ou limiter l’accès à tout ou partie du site, réserver l’accès au site, ou à certaines parties du site, à une catégorie déterminée d’internaute ;
                                    </li>
                                    <li>
                                        Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Suspendre le site afin de procéder à des mises à jour.
                            </p>
                            <h2>ARTICLE 5 : Protection des données personnelles
                            </h2>
                            <p>
                                En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il est rappelé que les données nominatives demandées à l’Utilisateur par la SAS JELAM Studio sur le site internet https://jelam.fr/ sont nécessaires pour le traitement de ses demandes.
                            </p>
                            <p>
                                Ces données sont communiquées aux personnes habilitées de la SAS JELAM Studio afin de leur permettre de procéder à la bonne exécution des demandes de l’Utilisateur.
                            </p>
                            <p>
                                Elles ne peuvent en aucun cas être utilisées à d'autres fins.
                            </p>
                            <p>
                                La SAS JELAM Studio s'engage à assurer la sécurité des données à caractère personnel qu'elle conserve pour les besoins de la réalisation et du suivi des demandes de l’Utilisateur.
                            </p>
                            <p>
                                Le traitement des informations communiquées par l'intermédiaire du site internet https://jelam.fr/ répond aux exigences légales en matière de protection des données personnelles, le système d'information utilisé assurant une protection optimale de ces données.
                            </p>
                            <p>
                                L’Utilisateur dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit d'accès permanent, de modification, de rectification, d'opposition de portabilité et de limitation du traitement s'agissant des informations le concernant.
                            </p>
                            <p>
                                Ce droit peut être exercé dans les conditions et selon les modalités définies dans la rubrique politique de confidentialité sur le site internet https://jelam.fr/.
                            </p>
                            <h2>ARTICLE 6 : Propriété intellectuelle</h2>
                            <p>
                                Le contenu du site internet https://jelam.fr/ est la propriété de la SAS JELAM Studio et de ses fournisseurs éventuels et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.
                            </p>
                            <p>
                                Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.
                            </p>
                            <h2>ARTICLE 7 : Responsabilités</h2>
                            <p>
                                La responsabilité de la SAS JELAM Studio à l'égard de l’Utilisateur ne peut être engagée que pour des faits qui lui seraient directement imputables et qui causeraient à l’Utilisateur un préjudice direct, à l'exclusion de tout préjudice indirect.
                            </p>
                            <p>
                                La responsabilité de la SAS JELAM Studio est exclue en cas de mauvaise utilisation des services par l’Utilisateur ou de faute de sa part.
                            </p>
                            <p>
                                Elle ne saurait pas plus être engagée à raison de faits imputables à un tiers, en cas de problématiques ou défaillances techniques, informatiques ou de compatibilité du site avec un matériel ou logiciel quel qu’il soit, des caractéristiques intrinsèques de l’Internet, notamment celles relatives au manque de fiabilité et au défaut de sécurisation des informations y circulant.
                            </p>
                            <p>
                                L’Utilisateur peut voir sa responsabilité engagée selon l’utilisation qu’il fait du site internet https://jelam.fr/ et s’il ne respecte ni la lettre ni l’esprit des présentes CGU.
                            </p>
                            <h2>ARTICLE 8 : Nullité partielle</h2>
                            <p>
                                Si une ou plusieurs stipulations des présentes sont tenues pour non valides ou déclarées comme telles en application d'une loi, d'un règlement ou d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et toute leur portée.
                            </p>
                            <h2>ARTICLE 9 : Droit applicable</h2>
                            <p>
                                Les présentes Conditions générales d'utilisation et les opérations qui en découlent sont régies par le droit français.
                            </p>
                            <p>
                                Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.
                            </p>
                            <h2>ARTICLE 10 : Identification</h2>
                            <p>
                                Nos informations de contacts sont les suivantes :
                            </p>
                            <div className="stg-bottom-gap">
                                <ul>
                                    <li>SAS JELAM Studio</li>
                                    <li>Adresse du siège social : 21 rue Jean Rostand 91400 ORSAY</li>
                                    <li>Capital social : 5000 €</li>
                                    <li>Numéro RCS 978 188 225 - RCS Evry</li>
                                    <li>Adresse E-mail : hello@jelam.fr</li>
                                </ul>
                            </div>
                            <h2>ARTICLE 11 : Litige</h2>
                            <p>Tout litige relatif à l'interprétation et/ou à l'exécution des présentes CGU relève des juridictions françaises.</p>
                            <p>Les réclamations motivées peuvent être adressées à notre service client :</p>
                            <p>
                                <ul>
                                    <li>Par téléphone : +33 (0)7 85 46 16 37</li>
                                    <li>Par le formulaire de contact disponible sur le site internet https://jelam.fr/</li>
                                    <li>Par courrier postal à l’adresse suivante : SAS JELAM Studio – 21 rue Jean Rostand 91400 ORSAY.</li>
                                    <li>Par mail à l’adresse de contact suivante : hello@jelam.fr</li>
                                </ul>
                            </p>
                            <p>Si après avoir épuisé les voies de recours auprès de notre service client, une incompréhension ou un désaccord persiste entre nous, et sous réserve des conditions de recevabilité, l’utilisateur est informé qu'il peut en tout état de cause recourir au médiateur des entreprises compétent pour connaître les litiges entre professionnels.</p>
                            <p><a href="https://www.economie.gouv.fr/mediateur-des-entreprises">https://www.economie.gouv.fr/mediateur-des-entreprises</a></p>
                            <p>L’Utilisateur, constatant qu'une violation au règlement général sur la protection des données personnelles aurait été commise, a la possibilité de mandater une association ou un organisme mentionné au IV de l'article 43 ter de la loi informatique et liberté de 1978, afin d'obtenir contre le responsable de traitement ou sous-traitant, réparation devant une juridiction civile ou administrative ou devant la commission nationale de l'informatique et des libertés.</p>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default privacy